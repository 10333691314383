import { useRef, useState } from 'react';

var useKeyboardNavigation = function useKeyboardNavigation() {
  var itemsRef = useRef([]);

  var _useState = useState(null),
      selectedItemIndex = _useState[0],
      setSelectedItemIndex = _useState[1];

  var focusableItemIndex = useRef(0);

  var registerItem = function registerItem(label, el) {
    if (!el) {
      return;
    }

    var index = itemsRef.current.findIndex(function (item) {
      return item.label === label;
    });

    if (index === -1) {
      itemsRef.current.push({
        label: label,
        el: el
      });
      return;
    }

    itemsRef.current[index] = {
      label: label,
      el: el
    };
  };

  var getItem = function getItem() {
    var _itemsRef$current$ite;

    var offset = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;

    if (selectedItemIndex === null) {
      return null;
    }

    var itemIndex = selectedItemIndex + offset;

    if (itemIndex < 0) {
      itemIndex += itemsRef.current.length;
    } else if (itemIndex >= itemsRef.current.length) {
      itemIndex -= itemsRef.current.length;
    }

    return (_itemsRef$current$ite = itemsRef.current[itemIndex]) !== null && _itemsRef$current$ite !== void 0 ? _itemsRef$current$ite : null;
  };

  var selectedItem = getItem();
  var nextItem = getItem(1);
  var previousItem = getItem(-1);

  var selectItem = function selectItem(item) {
    var unselect = function unselect() {
      if (selectedItem) {
        focusableItemIndex.current = selectedItemIndex !== null && selectedItemIndex !== void 0 ? selectedItemIndex : 0;
        selectedItem.el.blur();
      }

      setSelectedItemIndex(null);
    };

    if (!item) {
      unselect();
      return;
    }

    var index = itemsRef.current.findIndex(function (i) {
      return i.label === item.label;
    });

    if (index === -1) {
      unselect();
      return;
    }

    setSelectedItemIndex(index);
    item.el.focus();
  };

  var selectNextItem = function selectNextItem() {
    return selectItem(nextItem);
  };

  var selectPreviousItem = function selectPreviousItem() {
    return selectItem(previousItem);
  };

  var selectFocusableItem = function selectFocusableItem() {
    var _itemsRef$current;

    return selectItem((_itemsRef$current = itemsRef.current[selectedItemIndex !== null && selectedItemIndex !== void 0 ? selectedItemIndex : focusableItemIndex.current]) !== null && _itemsRef$current !== void 0 ? _itemsRef$current : null);
  };

  var selectLastItem = function selectLastItem() {
    var _itemsRef$current2;

    return selectItem((_itemsRef$current2 = itemsRef.current[itemsRef.current.length - 1]) !== null && _itemsRef$current2 !== void 0 ? _itemsRef$current2 : null);
  };

  var selectFirstItem = function selectFirstItem() {
    var _itemsRef$current$;

    return selectItem((_itemsRef$current$ = itemsRef.current[0]) !== null && _itemsRef$current$ !== void 0 ? _itemsRef$current$ : null);
  };

  var unselectItem = function unselectItem() {
    return selectItem(null);
  };

  return {
    selectedItem: selectedItem,
    nextItem: nextItem,
    previousItem: previousItem,
    selectNextItem: selectNextItem,
    selectPreviousItem: selectPreviousItem,
    selectFocusableItem: selectFocusableItem,
    selectFirstItem: selectFirstItem,
    selectLastItem: selectLastItem,
    registerItem: registerItem,
    unselectItem: unselectItem
  };
};

export default useKeyboardNavigation;