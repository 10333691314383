import { useCallback, useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import useEventCallback from '@segment/utils/useEventCallback/useEventCallback';

var useDropdown = function useDropdown() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      onOpen = _ref.onOpen,
      onClose = _ref.onClose,
      _ref$openWithArrows = _ref.openWithArrows,
      openWithArrows = _ref$openWithArrows === void 0 ? true : _ref$openWithArrows;

  var _useState = useState(false),
      isOpen = _useState[0],
      setIsOpen = _useState[1];

  var containerRef = useRef(null);
  var onOpenCallback = useEventCallback(onOpen, []);
  var onCloseCallback = useEventCallback(onClose, []);
  var open = useCallback(function (e) {
    if (isOpen) {
      return;
    }

    setIsOpen(true);
    onOpenCallback(e);
  }, [isOpen, onOpenCallback]);
  var close = useCallback(function () {
    if (!isOpen) {
      return;
    }

    setIsOpen(false);
    onCloseCallback();
  }, [isOpen, onCloseCallback]);
  var toggle = useCallback(function (e) {
    if (isOpen) {
      close();
    } else {
      open(e);
    }
  }, [isOpen, close, open]);
  useClickAway(containerRef, function () {
    close();
  });
  var getControlProps = useCallback(function () {
    return {
      role: "link",
      'aria-expanded': isOpen,
      'aria-owns': "listbox",
      'aria-haspopup': "listbox",
      onClick: function onClick(e) {
        return toggle(e);
      }
    };
  }, [isOpen]);
  var getBaseProps = useCallback(function () {
    var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        _onKeyDown = _ref2.onKeyDown;

    return {
      ref: containerRef,
      onKeyDown: function onKeyDown(e) {
        switch (e.key) {
          case "Escape":
            close();
            break;

          case "ArrowUp":
          case "ArrowDown":
            if (!openWithArrows) {
              return;
            }

            e.preventDefault();
            open(e);
            break;

          case "Enter":
          case "Space":
            if (isOpen) {
              return;
            }

            e.preventDefault();
            open(e);
            break;

          default:
            break;
        }

        _onKeyDown === null || _onKeyDown === void 0 ? void 0 : _onKeyDown(e);
      },
      onBlur: function onBlur(e) {
        if (e.currentTarget.contains(e.relatedTarget)) {
          return;
        }

        close();
      }
    };
  }, [isOpen, close, open, openWithArrows]);
  var getBodyProps = useCallback(function () {
    return {
      role: "listbox"
    };
  }, []);
  return {
    isOpen: isOpen,
    getBaseProps: getBaseProps,
    getControlProps: getControlProps,
    getBodyProps: getBodyProps
  };
};

export default useDropdown;